import {
  inject, Injectable, Injector
} from '@angular/core';
import { HotToastService } from '@ngxpert/hot-toast';
import { chain, filter } from 'lodash';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import concat from 'lodash/concat';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import _map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import {
  firstValueFrom, forkJoin, from, Observable
} from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CAMELCASE_SETTING_VALUES } from '~core/constants/camel-case-setting-values.constant';
import { AppState } from '~core/states/app/app.state';
import {
  FacilityDataKey,
  FacilityState,
  MasterlistSettings,
  PermitPrintingSettings
} from '~core/states/facility/facility.state';
import { SafeTKDB } from '~indexedDB';
import { HazardAnalysisCategory } from '~indexedDB/models/table-models';
import { OfflineDataSyncService } from '~offline/services/offline-data-sync/offline-data-sync.service';
import { NetworkState } from '~offline/states/network-state/network.state';
import { timeoutAsync } from '~shared/functions/timeout-async';
import { toCamelCase } from '~shared/functions/to-camel-case';
import { Dictionary } from '~shared/models/dictionary';
import { FacilitySetting } from '~shared/models/facility-setting.model';
import { PermitEditSettings, PermitSettingsObject } from '~shared/models/permit-edit-settings.model';
import { FacilitySettingSavedPermitDataService } from '~shared/services/apiFacilitySettingSavedPermitController';
import { MasterListDataService } from '~shared/services/apiMasterListController';
import { GeneralAppDataService } from '~shared/services/app-services/apiGeneralAppController';

import { SETTING_NAME_MAP, SETTING_VALUE_MAP } from '../../constants/facility-setting-maps.constant';

@Injectable({
  providedIn: 'root'
})
export class FacilitySettingService {

  private appState = inject(AppState);
  private generalAppDataSvc = inject(GeneralAppDataService);
  private indexedDB = inject(SafeTKDB);
  private injector = inject(Injector);
  private masterlistSvc = inject(MasterListDataService);
  private networkState = inject(NetworkState);
  private offlineDataSyncSvc = inject(OfflineDataSyncService);
  private savedPermitFacilitySettingSvc = inject(FacilitySettingSavedPermitDataService);
  private state = inject(FacilityState);
  private toast = inject(HotToastService);

  private facilityStateDataKeys = [
    'assetSettings',
    'attachmentSettings',
    'componentSettings',
    'dashboardIcons',
    'generalSettings',
    'noteTypes',
    'permitCreateSettings',
    'permitGroups',
    'permitIndexSettings',
    'permitSettings',
    'permitTypes',
    'statuses',
    'statusActions',
    'statusActionStatuses',
    'varietyTypes'
  ];

  get facilityId(): number {
    return this.appState.get('facilityId');
  }

  initFacilityState(): Observable<any> {
    this.toast.defaultConfig.duration = 2000;
    if (this.networkState.get('isOnline')) {
      /* Gets, parses, and sets all facility settings to facility state */

      this.appState.set('loading', true);
      return forkJoin([
        this.generalAppDataSvc.getEnabledPermitNames(),
        this.masterlistSvc.getMasterListTablesData('Container,Details,EnergyType,Hazard,HazardAnalysisCategory,IsolationPosition,Location,LockingDevice,Methods,Mitigation,Notes,NoteType,PermitGroup,Reason,ServicePosition,Status,StatusAction,StatusActionStatus,TagType,Task,TestPosition,TextChecked,VarietyType,VerificationRequirement,MasterListTable_01,MasterListTable_02,MasterListTable_03,MasterListTable_04', this.facilityId),
        this.getAllFacilitySettingsSaved('STIL:FacilitySettingSaved', this.facilityId),
        this.getAllFacilitySettingsSaved('FacilitySettingSavedPermit', this.facilityId),
        this.offlineDataSyncSvc.getAllAssociatedUserFieldUsers(this.facilityId)
      ])
        .pipe(
          switchMap(async ([
            permitTypes,
            masterlistTablesData,
            generalSettings,
            allPermitSettings,
            associatedUserFieldUsers
          ]) => {

            await this.indexedDB.db.masterlist.clear();
            await timeoutAsync(500);
            await this.indexedDB.db.masterlist.bulkAdd(masterlistTablesData.masterListData);

            const masterListDataByTable = groupBy(masterlistTablesData.masterListData, 'tableName');

            await this.saveHazardAnalysisCategories(masterListDataByTable.HazardAnalysisCategory);

            const permitTypeNames = _map(permitTypes, 'tableName');

            this.state.set('associatedUserFields', groupBy(associatedUserFieldUsers, 'itemType'));
            this.state.set('containers', groupBy(masterListDataByTable.Container, 'itemType'));
            this.state.set('details', groupBy(masterListDataByTable.Details, 'itemType'));
            this.state.set('hazards', groupBy(masterListDataByTable.Hazard, 'itemType'));
            this.state.set('methods', groupBy(masterListDataByTable.Methods, 'itemType'));
            this.state.set('mitigation', groupBy(masterListDataByTable.Mitigation, 'itemType'));
            this.state.set('notes', groupBy(masterListDataByTable.Notes, 'itemType'));
            this.state.set('noteTypes', groupBy(masterListDataByTable.NoteType, 'itemType'));
            this.state.set('permitGroups', groupBy(masterListDataByTable.PermitGroup, 'itemType'));
            this.state.set('permitTypes', permitTypeNames);
            this.state.set('reasons', groupBy(masterListDataByTable.Reason, 'itemType'));
            this.state.set('statuses', groupBy(masterListDataByTable.Status, 'itemType'));
            this.state.set('statusActions', groupBy(masterListDataByTable.StatusAction, 'itemType'));
            this.state.set('statusActionStatuses', groupBy(masterListDataByTable.StatusActionStatus, 'itemType'));
            this.state.set('tasks', groupBy(masterListDataByTable.Task, 'itemType'));
            this.state.set('varietyTypes', groupBy(masterListDataByTable.VarietyType, 'itemType'));

            this.state.set('allSettings', concat(generalSettings, allPermitSettings));
            const parsedGeneralSettings = this.parseGeneralSettings(generalSettings);
            const parsedPermitSettings = partition(allPermitSettings, { isStandard: true })
              .map(settingGroups => this.parsePermitSettings(settingGroups));

            const permitsEnabledOffline = _map(filter(generalSettings, { uniqueName: 'Permit_EnabledOffline' }), 'attribute');

            this.state.set('offlinePermitTypes', permitsEnabledOffline);

            return {
              generalSettings: parsedGeneralSettings,
              allPermitSettings: parsedPermitSettings
            };
          }),
          tap(({ generalSettings, allPermitSettings: [ standardSettings, permitSettings ] }) => {
            const {
              AssetStructure = {}, Dashboard = {}, General = {}, Module={}, PrintManage={}
            } = generalSettings;

            this.state.set('scanCodeRegex', AssetStructure?.ScanCodeRegex?.scanCodeRegex ?? '');
            this.state.set('assetSettings', AssetStructure?.Asset ?? {});
            // asset attachment setting names formatted to match permit settings
            const correctedAssetSettingProps = Object.entries(AssetStructure?.Attachment || {})
              .reduce((acc, [ key, value ]) => {
                acc[toCamelCase(key.replace(/attachment/g, ''))] = value;
                return acc;
              }, {});


            this.state.set('attachmentSettings', correctedAssetSettingProps);
            this.state.set('componentSettings', AssetStructure?.Component ?? {});
            this.state.set('dashboardIcons', Dashboard?.icons ?? {});
            this.state.set('generalSettings', General ?? {});
            this.state.set('moduleSettings', Module ?? {});
            this.state.set('permitIndexSettings', permitSettings.PermitIndex);
            this.state.set('permitSettings', permitSettings);
            this.state.set('printManageSettings', PrintManage);
            this.state.set('standardSettings', standardSettings);

            if (PrintManage.defaultPrintProfile) {
              const activePrintProfile = sessionStorage.getItem('printProfileName') || PrintManage.defaultPrintProfile;
              sessionStorage.setItem('printProfileName', activePrintProfile);
            }

          })
        );
    } else {
      return from(this.loadsessionStorage());
    }
  }

  async syncOfflineData(): Promise<void> {
    const offlineData = await Promise.all([
      firstValueFrom(this.offlineDataSyncSvc.getAllAssets()),
      firstValueFrom(this.offlineDataSyncSvc.getAllAuthorizedUsersByStatus(this.facilityId)),
      firstValueFrom(this.offlineDataSyncSvc.getAllHazardAnalysisCategoryItems(this.facilityId)),
      firstValueFrom(this.offlineDataSyncSvc.getAllPrefixes(this.facilityId)),
      firstValueFrom(this.offlineDataSyncSvc.getAllUsers(this.facilityId))
    ]);

    const [
      assets,
      allAuthorizedUsers,
      allHazardAnalysisCategoryItems,
      allPrefixes,
      allUsers
    ] = offlineData;

    this.state.set('prefixes', keyBy(allPrefixes, 'permitType'));

    await Promise.all([
      await this.indexedDB.db.assets.bulkPut(assets),
      this.indexedDB.db.users.bulkPut(allUsers),
      this.saveAuthorizedUsersForStatuses(allAuthorizedUsers.status),
      this.saveAuthorizedUsersForActionStatuses(allAuthorizedUsers.actionStatus),
      this.saveHazardAnalysisCategoryItems(allHazardAnalysisCategoryItems)
    ]);
  }

  async getAllFacilitySettingsSaved(tableName: string, facilityId: number, pageNumber = 1, pageSize = 75000, allFacilitySettings = []): Promise<any> {
    const pageResult = await firstValueFrom(this.savedPermitFacilitySettingSvc.getRecordsForFacilityID(tableName, facilityId, {
      pageNumber,
      pageSize
    }));

    allFacilitySettings = [ ...allFacilitySettings, ...pageResult ];

    if (pageResult.length >= pageSize - 1) {
      const nextPage = pageNumber + 1;
      allFacilitySettings = await this.getAllFacilitySettingsSaved(tableName, facilityId, nextPage, pageSize, allFacilitySettings);
    }

    return allFacilitySettings;
  }

  async loadsessionStorage(): Promise<void> {
    for (let key of this.facilityStateDataKeys) {
      let value = sessionStorage.getItem(key);

      if (!this.networkState.get('isOnline') && !value) {
        const cachedItem: any = await this.indexedDB.db.facilityState.get(key);
        value = cachedItem?.value;
      }

      if (value) {
        this.state.set(key as FacilityDataKey, JSON.parse(value));
      }
    }
  }

  parseGeneralSettings(generalSettings: any[]): Dictionary<any> {
    return chain(generalSettings)
      .groupBy(({ uniqueName }) => head(uniqueName.split('_')))
      .mapValues((groupedSettings, featureName) => {
        if (featureName === 'AssetStructure') {
          return mapValues(groupBy(groupedSettings, ({ uniqueName }) => uniqueName.split('_')[1]),
            (assetStructureSettings ) => this.parseFinalSettingObject(assetStructureSettings, featureName));
        }

        // TODO: good enough for now
        if (featureName === 'Module') {
          return mapValues(groupBy(groupedSettings, ({ attribute }) => attribute?.replace(/\s/g, "")),
            ( moduleSettings ) => this.parseFinalSettingObject(moduleSettings, featureName));
        }

        return this.parseFinalSettingObject(groupedSettings, featureName);
      })
      .value();
  }


  /* TODO: Make this recursive */
  /* TODO: Test swapping out chain in favor of flow */
  parsePermitSettings(permitSettings: any): any {
    return chain(permitSettings)
      .groupBy(({ uniqueName }) => head(uniqueName.split('_')))
      .mapValues((featureGroupSettings, featureName) => {

        return chain(featureGroupSettings).groupBy(({ itemType }) => itemType)
          .mapValues((permitTypeGroupSettings) => {

            return chain(permitTypeGroupSettings).groupBy(({ varietyTypeID }) => this.groupByOptionalProp(varietyTypeID))
              .mapValues((varietyTypeGroupSettings, varietyTypeId) => {
                if (varietyTypeId === 'settings') {
                  return this.parseFinalSettingObject(varietyTypeGroupSettings, featureName);
                }

                return chain(varietyTypeGroupSettings).groupBy(({ statusID }) => this.groupByOptionalProp(statusID))
                  .mapValues((statusGroupSettings, statusId) => {
                    if (statusId === 'settings') {
                      return this.parseFinalSettingObject(statusGroupSettings, featureName);
                    }

                    return chain(statusGroupSettings)
                      .groupBy(({ statusActionID }) => this.groupByOptionalProp(statusActionID))
                      .mapValues((statusActionGroupSettings, actionId) => {
                        if (actionId === 'settings') {
                          return this.parseFinalSettingObject(statusActionGroupSettings, featureName);
                        }

                        return chain(statusActionGroupSettings)
                          .groupBy(({ statusActionStatusID }) => this.groupByOptionalProp(statusActionStatusID))
                          .mapValues((statusActionStatusGroupSettings, statusActionStatusId) => {
                            if (statusActionStatusId === 'settings') {
                              return this.parseFinalSettingObject(statusActionStatusGroupSettings, featureName);
                            } else {
                              return { settings: this.parseFinalSettingObject(statusActionStatusGroupSettings, featureName) };
                            }
                          })
                          .value();
                      })
                      .value();
                  })
                  .value();
              })
              .value();
          })
          .value();
      })
      .value();
  }

  toSettingsObj(settings: FacilitySetting[], isBoolean = false): PermitSettingsObject {
    return reduce(settings, (settingsObj, { attribute, settingValue }: FacilitySetting) =>
      ({
        ...settingsObj,
        [attribute]: (isBoolean ? !!settingValue : settingValue)
      })
    , {});
  }

  private groupBySettingName(tabSettings: FacilitySetting[]): Dictionary<any> {
    const twoPartSettingNames = [
      'EditPermit_TabTags_ComponentStatus_SameUserCannot',
      'EditPermit_TabTags_ComponentStatus_ScanEnabled',
      'EditPermit_TabTags_ComponentStatus_Required',
      'EditPermit_TabTags_ComponentAuthorization_Enabled',
      'EditPermit_TabTags_ComponentAuthorization_BlockStatus',
      'PermitStatus_ComponentStatus_DeferAuth',
      'EditPermit_TabTags_ComponentStatus_Enabled',
      'EditPermit_TabTags_ComponentStatus_EnabledOnValidityCheck'
    ];

    return groupBy(tabSettings, ({ uniqueName }) => {

      let settingName = last(uniqueName.split('_'));

      /* special case for setting names with 2 parts separated by _ that are needed for proper naming */
      if (twoPartSettingNames.includes(uniqueName)) {

        const nameParts = uniqueName.split('_');

        settingName = nameParts.slice(nameParts.length - 2).join('_');
      }

      const keyName = SETTING_NAME_MAP[settingName] || settingName;


      return camelCase(keyName);
    });
  }

  private groupByOptionalProp(propValue: any, unsetGroupName = 'settings'): string | number {
    let groupKey: number | string = propValue;

    if (propValue === 0 || propValue === null) {
      groupKey = unsetGroupName;
    }

    return groupKey;
  }

  private groupByuniqueName(settings: FacilitySetting[]): any {
    return groupBy(settings, ({ uniqueName }) => {
      let groupKey = uniqueName
        .replace(/^(AssetStructure|CreatePermit|CommonPermits|Dashboard|General|EditPermit|PermitIndex|PermitStatus|PermitStatusAction|PermitStatusActionStatus)_/, '')
        .replace('Tab_', '');

      if (groupKey.startsWith('Tab') && groupKey.includes('_')) {
        groupKey = camelCase(head(groupKey.split('_')).replace('Tab', ''));
      }

      groupKey = SETTING_NAME_MAP[groupKey] ?? camelCase(groupKey);

      return groupKey;
    });
  }

  private parseFinalSettingObject(settings: any[], featureName: string): Dictionary<any> {
    const groupedSettings = groupBy(settings, 'uniqueName');

    switch (featureName) {
    case 'AssetStructure':
    case 'CreatePermit':
    case 'CommonPermits':
    case 'Dashboard':
    case 'EditPermit':
    case 'General':
    case 'PermitIndex':
    case 'PermitStatus':
    case 'PrintManage':
    case 'PermitStatusAction':
    case 'PermitStatusActionStatus':
      return this.parsePermitEditSettings(settings);

    case 'MasterList':
      return this.parseMasterListSettings(groupedSettings);

    case 'Permit':
      return this.parsePermitPrintingSettings(groupedSettings);

    default:
      return settings;
    }
  }

  private parsePermitEditSettings(allSettings: FacilitySetting[], recurse = false): PermitEditSettings {
    const settingsObj = recurse
      ? this.groupBySettingName(allSettings)
      : this.groupByuniqueName(allSettings);

    return reduce(settingsObj, (parsedSettings, value, key) => {

      const valueType = SETTING_VALUE_MAP[key];

      switch (valueType) {
      case 'ATTRIBUTE':
        parsedSettings[key] = value?.[0]?.attribute ?? value;
        break;

      case 'ATTRIBUTE_LIST':
        parsedSettings[key] = _map(value, 'attribute');

        if (CAMELCASE_SETTING_VALUES.includes(key)) {
          parsedSettings[key] = _map(parsedSettings[key], value => toCamelCase(value));
        }

        break;

      case 'BOOLEAN_ATTRIBUTE_OBJECT':
        parsedSettings[key] = reduce(value, (attrObj, { attribute }) => ({
          ...attrObj,
          [attribute]: true
        }), {});
        break;

      case 'BOOLEAN':
        parsedSettings[key] = true;
        break;

      case 'BOOLEAN_VALUE_OBJECT':
        parsedSettings[key] = this.toSettingsObj(value, true);

        if (CAMELCASE_SETTING_VALUES.includes(key)) {
          parsedSettings[key] = mapKeys(parsedSettings[key], (value, key) => toCamelCase(key));
        }
        break;

      case 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT':
        parsedSettings[key] = this.toNestedSettingsObjBool(value);
        break;

      case 'VALUE_NESTED_ATTRIBUTE_OBJECT':
        parsedSettings[key] = this.toNestedSettingsObj(value);
        break;

      case 'ATTRIBUTE_CONFIG_OBJECT':
        parsedSettings[key] = this.toConfigObj(value);
        break;

      case 'FUNCTIONS_ENABLED':
        parsedSettings[key] = this.toFunctionsEnabledObj(value);
        break;

      case 'TAB':
        (parsedSettings.tabs ??= {})[key] = this.parsePermitEditSettings(value, true);
        break;

      case 'VALUE':
        parsedSettings[key] = value?.[0]?.settingValue ?? value;
        break;

      case 'VALUE_LIST':
        parsedSettings[key] = _map(value, 'settingValue');
        break;

      case 'VALUE_OBJECT':
      default:
        parsedSettings[key] = this.toSettingsObj(value);

        if (CAMELCASE_SETTING_VALUES.includes(key)) {
          parsedSettings[key] = mapKeys(parsedSettings[key], (value, key) => toCamelCase(key));
        }
      }

      return parsedSettings;
    }, {} as any);
  }

  private parseMasterListSettings({ MasterList_Enabled }: Dictionary<FacilitySetting[]>): MasterlistSettings {
    return {
      enabledFields: this.toSettingsObj(MasterList_Enabled, true)
    };
  }

  private parsePermitPrintingSettings(settings: Dictionary<FacilitySetting[]>): PermitPrintingSettings {
    const { Permit_Printing_PrintListDefault, Permit_Printing_PrintListEnabled } = settings;

    const printSettings: PermitPrintingSettings = {
      enabledPrintLists: _map(Permit_Printing_PrintListEnabled, 'attribute')
    };

    if (Permit_Printing_PrintListDefault) {
      printSettings.defaultPrintList = Permit_Printing_PrintListDefault?.[0]?.settingValue;
    }

    return printSettings;
  }

  private toFunctionsEnabledObj(settings: FacilitySetting[]): Dictionary<boolean> {
    return reduce(settings, (settingsObj, { settingValue, uniqueName }: FacilitySetting) => {

      const nameParts = uniqueName.split('_');

      let key = capitalize(last(nameParts).replace('Enabled', ''));

      if (nameParts.length > 3) {
        key = `${nameParts[nameParts?.length - 2]}${key}`;
      }

      return {
        ...settingsObj,
        [camelCase(key)]: settingValue
      };
    }, {});
  }

  mapFieldsAndGroupByItemType(masterlistItems: any[]): Dictionary<any[]> {
    return groupBy(this.mapImportantFields(masterlistItems), 'itemType');
  }

  private mapImportantFields(masterlistItems: any[]): any  {
    return mapValues(masterlistItems, category => pick(category, [
      'TableName',
      'tableName',
      'ID',
      'itemType',
      'ItemType',
      'name',
      'Name',
      'displayOrder',
      'DisplayOrder',
      'users',
      'Users',
      'subType',
      'SubType'
    ]));
  }

  toNestedSettingsObjBool(settings: FacilitySetting[]): PermitSettingsObject {
    return reduce(settings, (settingsObj, { attribute, settingValue }: FacilitySetting) => {
      if (settingValue !== 'true' && attribute !== settingValue) {
        if (settingsObj?.[settingValue] !== true) {
          settingsObj[settingValue] ??= {};
          settingsObj[settingValue][attribute] = true;
        }

        return settingsObj;
      }

      return {
        ...settingsObj,
        [attribute]: true
      };
    }, {});
  }

  toNestedSettingsObj(settings: FacilitySetting[]): PermitSettingsObject {
    return reduce(settings, (settingsObj, {
      attribute, settingValue, config
    }: FacilitySetting) => {
      if (settingValue !== 'true' && attribute !== settingValue) {
        if (settingsObj?.[settingValue] !== true) {
          settingsObj[settingValue] ??= {};
          settingsObj[settingValue][attribute] = JSON.parse(config);
        }

        return settingsObj;
      }

      return {
        ...settingsObj,
        [attribute]: JSON.parse(config)
      };
    }, {});
  }

  toConfigObj(settings: FacilitySetting[]): PermitSettingsObject {
    return reduce(settings, (settingsObj, {
      attribute, settingValue, config
    }: FacilitySetting) => ({
      ...settingsObj,
      [attribute]: JSON.parse(config)
    }), {});
  }

  private async saveAuthorizedUsersForStatuses(authorizedUsersByStatus: any[]): Promise<void> {
    for (let status of authorizedUsersByStatus) {
      const statusAuthorizedUsers = [];
      for (let varietyType of status.varietyTypes) {
        statusAuthorizedUsers.push({
          ID: status.ID,
          varietyTypeID: varietyType.ID,
          users: varietyType?.users ?? []
        });
      }

      await this.indexedDB.db.authorizedUserByStatus.bulkPut(statusAuthorizedUsers);
    }
  }

  private async saveAuthorizedUsersForActionStatuses(authorizedUsersByActionStatus: any[]): Promise<void> {
    for (let actionStatus of authorizedUsersByActionStatus) {
      const actionStatusAuthorizedUsers = [];
      for (let varietyType of actionStatus.varietyTypes) {
        actionStatusAuthorizedUsers.push({
          ID: actionStatus.ID,
          varietyTypeID: varietyType.ID,
          users: varietyType?.users ?? []
        });
      }

      await this.indexedDB.db.authorizedUserByActionStatus.bulkPut(actionStatusAuthorizedUsers);
    }
  }

  private async saveHazardAnalysisCategories(masterListHazardAnalysisCategories: any[]): Promise<void> {
    try {
      const hazardAnalysisCategories: HazardAnalysisCategory[] = [];

      for (let category of masterListHazardAnalysisCategories) {
        hazardAnalysisCategories.push({
          categoryID: category.ID,
          categoryName: category.name,
          displayOrder: category.displayOrder,
          permitType: category.itemType
        });
      }

      await this.indexedDB.db.hazardAnalysisCategory.bulkPut(hazardAnalysisCategories);
    } catch (err)  {
      this.toast.error(`There was an issue saving hazard analysis categories to indexedDB!`);
      console.debug('Error saving hazard analysis categories to indexedDB', err);
    }

  }

  private async saveHazardAnalysisCategoryItems(hazardCategoryItemsByPermitAndVarietyType: any[]): Promise<void> {
    for (let hazardPermitType of hazardCategoryItemsByPermitAndVarietyType) {

      const permitTypeHazardCategoryItems = [];

      for (let varietyType of hazardPermitType.varietyTypes) {
        for (let hazardCategory of varietyType.nonStandards) {
          permitTypeHazardCategoryItems.push({
            ...hazardCategory,
            permitType: hazardPermitType.permitType,
            varietyTypeID: varietyType.ID,
            isStandard: 'false'
          });
        }

        for (let hazardCategory of varietyType.standards) {
          permitTypeHazardCategoryItems.push({
            ...hazardCategory,
            permitType: hazardPermitType.permitType,
            varietyTypeID: varietyType.ID,
            isStandard: 'true'
          });
        }
      }

      await this.indexedDB.db.hazardAnalysisCategoryItems.bulkPut(permitTypeHazardCategoryItems);
    }
  }

}
